.App {
  text-align: center;
  --oberlin-red: #cf102d;
}

button {
  cursor: pointer;
  text-align: left;
  display: flex;
  background-color: transparent;
  border: none;
}

a {
  text-decoration: none;
  color: black;
}

p {
  font-size: 14px;
}
